import React, { useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/custom-button/custom-button.component';
import AlexHeadshot from '../../img/alex-headshot.png';
import HeroImg from '../../img/hero-img.png';
import LuisHeadshot from '../../img/luis-headshot.png';
import OmarHeadshot from '../../img/omar-headshot.png';

import Step1 from '../../img/step-1.png';
import Step2 from '../../img/step-2.png';
import Step3 from '../../img/step-3.png';
import Step4 from '../../img/step-4.png';
import Step5 from '../../img/step-5.png';
import Step6 from '../../img/step-6.png';

import Footer from '../footer/footer.route';
import './homepage.styles.scss';

const Homepage = () => {
  let navigate = useNavigate();
  const aboutRef = useRef(null);
  const productRef = useRef(null);

  const executeAboutScroll = () =>
    aboutRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  const executeProductScroll = () =>
    productRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const handleClick = useCallback(() => {
    document.body.classList.remove('sticky');

    navigate(`/demo`);
  }, [navigate]);

  return (
    <main>
      <section className='section-hero'>
        <div className='hero'>
          <div className='hero__text-box'>
            <h1 className='heading-primary'>
              Next-generation AI for frictionless visual telemedicine
            </h1>

            <p className='heading-tertiary'>
              Produce accurate skin assessments on patients using a detailed 5-step image analysis
              AI.
            </p>

            <CustomButton className='btn btn--full margin-right-sm' onClick={handleClick}>
              Access Demo
            </CustomButton>
            <CustomButton className='btn btn--outline' onClick={executeProductScroll}>
              Learn more &darr;
            </CustomButton>
          </div>
          <div className='hero__img-box'>
            <img src={HeroImg} alt='hero section' className='hero__img-box--img' />
          </div>
        </div>
      </section>
      <section className='section-about' id='about' ref={aboutRef}>
        <div className='container about__heading'>
          <p className='heading-secondary margin-bottom-ex-sm'>About Us</p>
          <p className='heading-tertiary'>
            developed by a team of engineers and doctors from MIT, Harvard, and ASU
          </p>
        </div>
        <div className='container grid grid--3-cols'>
          <div className='about__card'>
            <img src={LuisHeadshot} alt='Luis Soenksin' className='about__card--img' />
            <p className='about__card--name heading-tertiary'>Luis Soenksin PhD</p>
            <p className='about__card--bio paragraph text-grey-color'>
              Serial entrepreneur and medical device expert. MIT and Johns Hopkins BME graduate
              currently acting as MIT’s first Venture Builder in Artificial Intelligence and
              Healthcare.
            </p>
          </div>
          <div className='about__card'>
            <img src={OmarHeadshot} alt='Omar Badri' className='about__card--img' />
            <p className='about__card--name heading-tertiary'>Omar Badri M.D</p>
            <p className='about__card--bio paragraph text-grey-color'>
              Serial-entrepreneur, Internal Medicine, Co-founder of Medumo - company to guide,
              track, triage and educate patients (acquired by Phillips 2019). Practices at Brigham
              and Women’s Hospital (BWH) and Harvard Medical School.
            </p>
          </div>
          <div className='about__card'>
            <img src={AlexHeadshot} alt='Alex Alimov' className='about__card--img' />
            <p className='about__card--name heading-tertiary'>Alex Alimov</p>
            <p className='about__card--bio paragraph text-grey-color'>
              Full-stack Software developer +10Y Experience in Mobile IOS, Android, Cloud.
            </p>
          </div>
        </div>
      </section>
      <section className='section-product' id='product' ref={productRef}>
        <div className='container product__heading'>
          <p className='heading-secondary margin-bottom-ex-sm'>Product</p>
          <p className='heading-tertiary'>5-step image analysis for accurate skin assessments </p>
        </div>
        <div className='container product-steps'>
          <div className='product-steps__step grid grid--2-cols product-steps--step-1'>
            <div className='product-steps__step__details'>
              <h1 className='heading-primary product-steps__step__details--number'>01</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Image quality assessment
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                Every image is assigned a quality score to determine if an accurate analysis can be
                performed by automated algorithms
              </h3>
            </div>

            <div className='product-steps__step__img-box'>
              <img src={Step1} alt='Step 1' className='product-steps__step__img-box--img' />
            </div>
          </div>
          <div className='product-steps__step grid grid--2-cols product-steps--step-2'>
            <div className='product-steps__step__img-box'>
              <img src={Step2} alt='Step 2' className='product-steps__step__img-box--img' />
            </div>
            <div className='product-steps__step__details'>
              <h1 className='product-steps__step__details--number heading-primary'>02</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Body Location mapping
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                Visible body parts in submitted images are automatically recognized, mapped and
                tracked
              </h3>
            </div>
          </div>
          <div className='product-steps__step grid grid--2-cols product-steps--step-3'>
            <div className='product-steps__step__details'>
              <h1 className='product-steps__step__details--number heading-primary'>03</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Fitzpatrick skin type
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                Upon face detection, a skin color palette is recognized to calculate the average
                phototype of the patient. Other features such as age, sex and ethnic skin are
                automatically infered to facilitate reporting
              </h3>
            </div>

            <div className='product-steps__step__img-box'>
              <img src={Step3} alt='Step 3' className='product-steps__step__img-box--img' />
            </div>
          </div>
          <div className='product-steps__step grid grid--2-cols product-steps--step-4'>
            <div className='product-steps__step__img-box'>
              <img src={Step4} alt='Step 4' className='product-steps__step__img-box--img' />
            </div>
            <div className='product-steps__step__details'>
              <h1 className='product-steps__step__details--number heading-primary'>04</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Image-based Differential Diagnosis
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                Our pre-trained deep learning algorithm receives image crops from received photos
                and produces arank ordered by diagnostic probability across 250 diseases with visual
                presentations
              </h3>
            </div>
          </div>
          <div className='product-steps__step grid grid--2-cols product-steps--step-5'>
            <div className='product-steps__step__details'>
              <h1 className='product-steps__step__details--number heading-primary'>05</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Question-based Refinement
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                A short list of highly focused questions is selected based on the determined
                differential diagnosis. These questions are designed to improve diagnosis
                probability and work through the short list of possible diagnoses
              </h3>
            </div>
            <div className='product-steps__step__img-box'>
              <img src={Step5} alt='Step 5' className='product-steps__step__img-box--img' />
            </div>
          </div>
          <div className='product-steps__step grid grid--2-cols product-steps--step-6'>
            <div className='product-steps__step__img-box'>
              <img src={Step6} alt='Step 6' className='product-steps__step__img-box--img' />
            </div>
            <div className='product-steps__step__details'>
              <h1 className='product-steps__step__details--number heading-primary'>06</h1>
              <h2 className='product-steps__step__details--title heading-secondary'>
                Final Report
              </h2>
              <h3 className='product-steps__step__details--description heading-tertiary'>
                Physician is presented with full report of aggregated information to allow for best
                decision making process in least amount of review time
              </h3>
            </div>
          </div>
        </div>
      </section>
      <Footer executeAboutScroll={executeAboutScroll} executeProductScroll={executeProductScroll} />
    </main>
  );
};

export default Homepage;
