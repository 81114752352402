import React, { useCallback, useState } from 'react';
import { IoCloseOutline, IoMenuOutline } from 'react-icons/io5';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import logo from '../../img/logo.png';
import './navigation.styles.scss';

const Header = ({ executeAboutScroll, executeProductScroll }) => {
  let navigate = useNavigate();
  const [nav_open, set_open] = useState('');

  const handleAccessDemoLinkClick = useCallback(() => {
    set_open('');
    document.body.classList.remove('sticky');

    navigate(`/demo`);
  }, [navigate]);

  const handleLinkClick = (event) => {
    set_open('');
    if (event === 'about') {
      executeAboutScroll();
    }

    if (event === 'product') {
      executeProductScroll();
    }
  };

  return (
    <>
      <header className={`header ${nav_open}`}>
        <Link to='/'>
          <img src={logo} className='logo' alt='Medicall logo' />
        </Link>

        <nav className='main-nav'>
          <ul className='main-nav-list'>
            <li>
              <Link
                className='main-nav-link'
                to='/#about'
                onClick={() => handleLinkClick('about')}
                reloadDocument>
                About Us
              </Link>
            </li>
            <li>
              <Link
                className='main-nav-link'
                to='/#product'
                onClick={() => handleLinkClick('product')}
                reloadDocument>
                Product
              </Link>
            </li>
            <li>
              <a className='main-nav-link' href='mailto:omar@medicall.com'>
                Contact
              </a>
            </li>

            <li>
              <Link
                className='main-nav-link nav-cta'
                to='/demo'
                onClick={handleAccessDemoLinkClick}>
                Access Demo
              </Link>
            </li>
          </ul>
        </nav>

        <button
          className='btn-mobile-nav'
          onClick={() => {
            if (nav_open) {
              set_open('');
            } else {
              set_open('nav-open');
            }
          }}>
          <IoMenuOutline className='icon-mobile-nav' name='menu-outline' />
          <IoCloseOutline className='icon-mobile-nav' name='close-outline' />
        </button>
      </header>
      <Outlet />
    </>
  );
};

export default Header;
