import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GaugeChart from 'react-gauge-chart';
import { formatResponse } from '../../util/parse-response.util';
import { useQuery } from '../../util/use-query.hooks';
import Footer from '../footer/footer.route';

const ViewAnalysis = () => {
  const [errors, setErrors] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [loading, setLoading] = useState(false);
  const [stillProcessing, setStillProcessing] = useState(false);

  let query = useQuery();

  useEffect(() => {
    // setLoading(true);
    // async function fetchData() {
    //   await getAnalysis();
    // }
    // fetchData();

    const timer = setTimeout(async () => {
      await getAnalysis();
    }, 12000);

    return () => clearTimeout(timer);

    // const sampleResponse = formatSampleResponse();
    // setResponseData(sampleResponse);
    // setLoading(false);
  }, []);

  const getAnalysis = async () => {
    const jobId = query.get('job_id');

    setLoading(true);

    try {
      const imgDataRequest = await axios({
        method: 'GET',
        url: `https://d1poupjstwrosh.cloudfront.net/api/get_result?job_id=${jobId}`,
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (imgDataRequest.status === 200) {
        if (imgDataRequest.data.msg === 'processing') {
          setResponseData(imgDataRequest.data);
          setStillProcessing(true);
        } else {
          setResponseData(formatResponse(imgDataRequest.data.data));
        }
      }
    } catch (error) {
      console.log(error.response);
      setErrors([error]);
    }
    setLoading(false);
  };

  const loadingUI = () => {
    return (
      <div className='container center-text'>
        <h1 className='heading-primary'>Loading...</h1>
        <h1 className='heading-tertiary'>Please wait a few seconds.</h1>
      </div>
    );
  };

  const stillProcessingUI = () => {
    return (
      <div className='container center-text'>
        <h1 className='heading-primary'>This image is still being processed.</h1>
        <h1 className='heading-tertiary'>Refresh the page in a few seconds.</h1>
      </div>
    );
  };

  const errorUI = () => {
    return (
      <div className='container center-text'>
        <h1 className='heading-primary'>There was an error analyzing this image</h1>
        <h1 className='heading-tertiary'>Please email alex@medicall.com for assistance.</h1>
      </div>
    );
  };

  if (stillProcessing) {
    return stillProcessingUI();
  }

  if (errors.length > 0) {
    return errorUI();
  }

  if (loading || !responseData.qualityScore) {
    return loadingUI();
  }

  const chartStyle = {
    width: '50%',
  };

  return (
    <div className='container'>
      <section className='quality-score margin-bottom-lg'>
        <div className='container'>
          <h2 className='heading-secondary margin-bottom-ex-sm'>Image Quality Score</h2>
          <p className='heading-tertiary'>Below you will find the quality score for the image</p>
        </div>
        <div className='container'>
          <GaugeChart
            id='quality-score-chart'
            marginInPercent={0}
            textColor='var(--color-primary)'
            arcsLength={[0.4, 0.35, 0.25]}
            colors={['#EA4228', '#F5CD19', '#5BE12C']}
            percent={responseData.qualityScore / 100}
            arcPadding={0.02}
            style={chartStyle}
          />
        </div>
      </section>
      <section className='facial-recognition margin-bottom-lg'>
        <div className='container'>
          <h2 className='heading-secondary margin-bottom-ex-sm'>Facial Recognition</h2>
          <p className='heading-tertiary'>
            Below you will find an analysis of the subjects face in the image
          </p>
        </div>
        <div className='container'>
          <div className='margin-bottom-md'>
            <h3 className='heading-tertiary margin-bottom-ex-sm'>Age</h3>
            <p className='paragraph'>
              {responseData.faceAnalysis.age ? responseData.faceAnalysis.age : 'N/A'}
            </p>
          </div>
          <div className='margin-bottom-md'>
            <h3 className='heading-tertiary margin-bottom-ex-sm'>Gender</h3>
            <p className='paragraph'>
              {responseData.faceAnalysis.gender ? responseData.faceAnalysis.gender : 'N/A'}
            </p>
          </div>
          <div className='margin-bottom-md'>
            <h3 className='heading-tertiary margin-bottom-ex-sm'>Dominant Emotion</h3>
            <p className='paragraph'>
              {responseData.faceAnalysis.dominant_emotion
                ? responseData.faceAnalysis.dominant_emotion
                : 'N/A'}
            </p>
          </div>
          <div className='margin-bottom-md'>
            <h3 className='heading-tertiary margin-bottom-ex-sm'>Dominant Race</h3>
            <p className='paragraph'>
              {responseData.faceAnalysis.dominant_race
                ? responseData.faceAnalysis.dominant_race
                : 'N/A'}
            </p>
          </div>
        </div>
      </section>
      <section className='diagnosis margin-bottom-lg'>
        <div className='container'>
          <h2 className='heading-secondary margin-bottom-ex-sm'>
            Image-based differential diagnosis
          </h2>
          <p className='heading-tertiary'>
            Here are the top 5 possible diagnoses with corresponding values
          </p>
        </div>
        <div className='container'>
          {[...responseData.diagnosisMap.entries()].map((diagnosis) => {
            return (
              <div key={diagnosis[0]} className='margin-bottom-md'>
                <h3 className='heading-tertiary margin-bottom-ex-sm'>{diagnosis[0]}</h3>
                <p className='paragraph'>{diagnosis[1]}</p>
              </div>
            );
          })}
        </div>
      </section>
      <section className='questions margin-bottom-lg'>
        <div className='container'>
          <h2 className='heading-secondary margin-bottom-ex-sm'>Question-based refinement</h2>
          <p className='heading-tertiary'>
            Below are a list of follow-up questions to ask for each diagnosis
          </p>
        </div>
        <div className='container'>
          {Object.entries(responseData.questions)
            .reverse()
            .map((questionObj) => (
              <div key={questionObj[0]} className='margin-bottom-md'>
                <h3 className='heading-tertiary margin-bottom-ex-sm'>{questionObj[0]}</h3>
                {questionObj[1].length > 0 ? (
                  questionObj[1].map((question, index) => (
                    <p key={index} className='paragraph'>
                      {question}
                    </p>
                  ))
                ) : (
                  <p className='paragraph'>N/A</p>
                )}
              </div>
            ))}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ViewAnalysis;
