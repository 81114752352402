import { useLayoutEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Demo from './routes/demo/demo.route';
import Homepage from './routes/homepage/homepage.route';
import LegalPage from './routes/legal/legal.pages';
import Navigation from './routes/navigation/navigation.route';
import ViewAnalysis from './routes/view-analysis/view-analysis.route';

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  return (
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<Navigation />}>
          <Route index element={<Homepage />} />
          <Route path='/demo' element={<Demo />} />
          <Route path='/analysis' element={<ViewAnalysis />} />
          <Route path='/privacy' element={<LegalPage page='privacy' />} />
          <Route path='/terms' element={<LegalPage page='terms' />} />
        </Route>
      </Routes>
    </ScrollToTop>
  );
};

export default App;
