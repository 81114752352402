import React from 'react';
import Footer from '../footer/footer.route';
import './legal.styles.scss';

import { privacy_text, terms_text } from './legal.text';

const LegalPage = ({ page = '' }) => {
  let legalText = '';
  let legalHeader = '';
  if (page === 'privacy') {
    legalHeader = 'Privacy Policy';
    legalText = privacy_text;
  } else if (page === 'terms') {
    legalHeader = 'Terms and Conditions';
    legalText = terms_text;
  }
  return (
    <div>
      <div className='legal-container'>
        <div className='container'>
          <h1 className='legal-container__header'>{legalHeader}</h1>
          <p className='legal-container__text'>{legalText}</p>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LegalPage;
