import axios from 'axios';
import React, { useState } from 'react';
import Files from 'react-butterfiles';
import { IoImageOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import CustomButton from '../../components/custom-button/custom-button.component';
import Footer from '../footer/footer.route';
import './demo.styles.scss';

const Demo = () => {
  const [photos, setPhotos] = useState([]);
  const [errors, setErrors] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [dragging, setDragging] = useState(false);
  let navigate = useNavigate();

  const handleImages = (files) => {
    setPhotos(files);
  };
  const handleErrors = (errors) => {
    setErrors(errors);
  };

  const handleSubmit = async () => {
    if (photos.length === 0) {
      toast.info('Please upload photos before continuing');
      return;
    }

    if (submitted) {
      return;
    }

    toast.info('Uploading photo...please wait');

    setSubmitted(true);

    try {
      const postImageRequest = await axios({
        method: 'POST',
        url: 'https://d1poupjstwrosh.cloudfront.net/api/post_image',
        headers: {
          'Content-Type': photos[0].type,
        },
        // Base64 value of image
        data: photos[0].src.file,
      });

      if (postImageRequest.status === 200) {
        console.log(postImageRequest.response);

        navigate(`/analysis?job_id=${postImageRequest.data.job_id}`);
      }
    } catch (error) {
      console.log(error.response);
      setErrors([error]);
    }

    setSubmitted(false);
  };

  return (
    <div id='container margin-center'>
      <div className='container center-text'>
        <p className='heading-secondary margin-bottom-ex-sm'>Upload a photo</p>
        <p className='heading-tertiary'>Drag and drop your image in the box</p>
      </div>
      <div className='container center-text margin-bottom-md'>
        <Files
          multiple={false}
          convertToBase64
          maxSize='50mb'
          accept={['image/jpg', 'image/jpeg', 'image/png']}
          onSuccess={handleImages}
          onError={handleErrors}>
          {({ browseFiles, getDropZoneProps }) => {
            return (
              <>
                {errors.length > 0 && (
                  <div className='container center-text'>
                    <h1 className='heading-secondary'>An error occurred.</h1>
                    {errors.map((error) => (
                      <p className='paragraph' key={error.id}>
                        {error.file ? (
                          <span>
                            {error.file.name} - {error.type}
                          </span>
                        ) : (
                          error.type
                        )}
                      </p>
                    ))}
                  </div>
                )}
                <div
                  {...getDropZoneProps({
                    id: 'my-image-gallery',
                    className: 'margin-bottom-md photo-gallery' + (dragging ? ' dragging' : ''),
                    onDragEnter: () => setDragging(true),
                    onDragLeave: () => setDragging(false),
                    onDrop: () => setDragging(false),
                  })}>
                  <ul>
                    {photos.map((image, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          browseFiles({
                            onErrors: handleErrors,
                            onSuccess: (files) => {
                              // Will update image.
                              handleImages(files);
                            },
                          });
                        }}>
                        <img src={image.src.base64} alt='User selected' />
                      </li>
                    ))}
                    <li
                      className='new-image'
                      onClick={() => {
                        browseFiles({
                          onErrors: handleErrors,
                          onSuccess: (files) => {
                            // Will update image.
                            handleImages(files);
                          },
                        });
                      }}>
                      <div>
                        <IoImageOutline className='list-icon margin-bottom-sm' />
                        <p>+ Tap to upload photo</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </>
            );
          }}
        </Files>
        <CustomButton className='btn btn--full' onClick={handleSubmit}>
          Submit and Continue
        </CustomButton>
      </div>
      {submitted && (
        <div className='spinner-overlay'>
          <div className='spinner-container' />
        </div>
      )}

      <Footer />

      <ToastContainer
        position='top-right'
        bodyClassName='toastBody'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Demo;
