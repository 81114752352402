import React from 'react';
import { Link } from 'react-router-dom';
import HIPAABadge from '../../img/hipaa-badge.png';
import Logo from '../../img/logo.png';
import './footer.styles.scss';

const Footer = ({ executeAboutScroll, executeProductScroll }) => {
  return (
    <footer className='footer'>
      <Link to='/' className='footer--logo'>
        <img src={Logo} alt='Medicall logo' className='logo' />
      </Link>

      <ul className='footer__nav'>
        <li>
          <Link
            to='/#product'
            onClick={executeProductScroll}
            className='footer__nav--link heading-tertiary'>
            Product
          </Link>
        </li>
        <li>
          <Link
            to='/#about'
            onClick={executeAboutScroll}
            className='footer__nav--link heading-tertiary'>
            About Us
          </Link>
        </li>
        <li>
          <a className='footer__nav--link heading-tertiary' href='mailto:omar@medicall.com'>
            Contact
          </a>
        </li>
      </ul>

      <ul className='footer__terms'>
        <Link to='/terms' className='footer__nav--link heading-tertiary'>
          Terms of Service
        </Link>
        <Link to='/privacy' className='footer__nav--link heading-tertiary'>
          Privacy Policy
        </Link>
      </ul>

      <div className='footer__copyright'>
        <img className='footer__copyright--hipaa-icon' src={HIPAABadge} alt='HIPAA Bage' />

        <p className='footer__copyright--text paragraph'>
          Copyright &copy; <span className='year'>2022</span>
          <br />
          Medicall, Inc.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
