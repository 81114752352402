import { sample } from './sample-response';

export const formatSampleResponse = () => {
  return formatResponse(sample.data);
};

export const formatResponse = (data) => {
  const diagnosis = JSON.parse(data.diagnosis);

  const qualityScore = Number(data.quality);
  const faceAnalysis = JSON.parse(data.face_analysis);
  const questions = diagnosis.questions;
  const diagnosisHashMap = arrayToObj(data.labels, diagnosis.vec);

  const map = new Map(Object.entries(diagnosisHashMap));

  const sortedVecDesc = new Map([...map.entries()].sort((a, b) => b[1] - a[1]));

  const trimmedDiagnosisHashmap = {};

  [...sortedVecDesc.entries()].every((element, index) => {
    const sortedValue = diagnosisHashMap[element[0]];
    trimmedDiagnosisHashmap[element[0]] = sortedValue;
    if (index === 4) {
      return false;
    }

    return true;
  });

  const trimmedMap = new Map(Object.entries(trimmedDiagnosisHashmap));

  return {
    qualityScore,
    faceAnalysis,
    questions,
    diagnosisMap: trimmedMap,
  };
};

const arrayToObj = (keys, vals) => {
  var map = {};
  keys.forEach(function (key, index) {
    map[key] = vals[index];
  });
  return map;
};
